.preloader {
  position: fixed;
  background-color: $color-primary;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: none;
  z-index: 99999;

  &__inner {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
  }

  &__img {
    display: flex;
    width: 742.12/16+em;

    @media screen and (max-width: $laptop-s) {
      width: 90%;
      margin: 0 auto;
    }

    img {
      flex-shrink: 0;

      &:first-child {
        width: 103.81%/742.12*100;
        padding: 0 1%;
        transform: rotate(-180deg);
        transition: 1s;
        transition-delay: .5s;
        transform-origin: 50% 45%;
      }

      &:last-child {
        width: 638.32%/742.12*100;
      }
    }
  }

  &__title {
    font-size: 30/16+em;
    text-align: center;
    color: #F2F2F2;
    opacity: 0;
    transition: 1.5s;

    @media screen and (max-width: $laptop-s) {
      font-size: 3.5vw;
    }
  }

  &__scroll {
    position: absolute;
    bottom: 3em;
    left: 50%;
    font-size: 32/16+em;
    width: 1em;
    margin-left: -.5em;
    transform: translateY(-2em);

    @keyframes wiggle {
      0% {
        transform: translateY(-2em);
      }
      10% {
        transform: translateY(0);
      }
      85% {
        transform: translateY(0);
      }
      100% {
        transform: translateY(-2em);
      }
    }
  }

  &.animated {
    .preloader__img img:first-child {
      transform: rotate(0.1deg);
    }

    .preloader__title {
      opacity: 1;
    }

    .preloader__scroll {
      animation: wiggle infinite 4s linear;
    }
  }
}