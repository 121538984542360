.dates-section {
  background: linear-gradient(180deg, #FFFFFF 0%, rgba(255, 255, 255, 0) 42.19%, #FFFFFF 100%), url("/assets/img/dates-bg.png") center center;
  background-size: cover;

  .dates {
    position: relative;
    padding-bottom: 5em;

    @media screen and (max-width: $tablet) {
      margin-top: 3rem;
    }

    &:before {
      content: '';
      height: 100%;
      top: 0;
      width: 4px;
      position: absolute;
      left: 50%;
      transform: translateX(-50%);
      background: linear-gradient(to bottom, #B25333, transparent);

      @media screen and (max-width: $tablet) {
        left: 12/16+rem;
      }
    }

    &__column {
      display: flex;
      flex-direction: column;
      position: relative;

      .date-item {
        width: 50%;
        display: flex;
        flex-direction: column;
        align-items: flex-end;
        text-align: right;
        padding-right: 55/16+em;

        @media screen and (max-width: $tablet) {
          width: 100%;
          text-align: left;
          padding-right: unset;
          align-items: flex-start;
          padding-left: 55/16+em;

          .date-item__value {
            &:after {
              left: auto;
              right: calc(100% + 22rem/16);
              transform: matrix(-1, 0, 0, 1, 0, 0);
            }
          }

          &:not(:last-child) {
            margin-bottom: 1.5rem;
          }
        }

        &:first-child {
          margin-top: -1.6em;
        }

        &__value {
          color: $color-primary;
          font-weight: 600;
          font-size: 36/16+em;
          letter-spacing: -0.1/36+em;
          position: relative;

          &:after {
            top: 50%;
            content: '';
            width: 40/16+rem;
            height: 28/16+rem;
            margin-top: -14/16+rem;
            background: url("/assets/img/point.svg") center center;
            filter: drop-shadow(0 4px 4px rgba(0,0,0,.25));
            display: block;
            position: absolute;
            left: calc(100% + 35rem/16);
          }
        }

        &__desc {
          font-size: 14/16+em;
          line-height: 20/14+em;
          letter-spacing: 0.1/14+em;
          color: #000000;
        }

        &:nth-of-type(2n+2) {
          align-self: flex-end;
          align-items: flex-start;
          text-align: left;
          padding-left: 55/16+em;

          .date-item__value {
            &:after {
              left: auto;
              right: calc(100% + 35rem/16);
              transform: matrix(-1, 0, 0, 1, 0, 0);

              @media screen and (max-width: $tablet) {
                right: calc(100% + 22rem/16);
              }
            }
          }
        }

        &:not(:first-child) {
          .date-item__value {
            margin-top: -1.1em;

            @media screen and (max-width: $tablet) {
              margin-top: 0;
            }
          }
        }
      }
    }

    &__hidden {
      display: none;

      @media screen and (max-width: $tablet) {
        margin-top: 1.5rem;
      }

      .date-item {
        opacity: 0;
        animation: 1.5s forwards fadeIn;

        &:first-child {
          padding-top: 1em;
        }
      }

      @keyframes fadeIn {
        0% {
          opacity: 0;
        }
        100% {
          opacity: 1;
        }
      }
    }
  }
}