.story {
  padding: 136/16+em 118/16+em 62/16+em;
  border-radius: 29/16+em;
  overflow: hidden;
  display: block !important;
  position: relative;
  height: 100%;

  @media screen and (max-width: $tablet) {
    padding: 88/16+em 35/16+em 34/16+em;
  }

  &:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1;
    background: linear-gradient(104.99deg, #1B4167 3.35%, rgba(27, 65, 103, 0.907425) 33.57%, rgba(27, 65, 103, 0) 121.31%);
  }

  &__bg {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    @include object-fit(cover);
    z-index: 0;
  }

  &>div {
    position: relative;
    z-index: 2;
  }

  &__quote {
    color: #fff;
    font-weight: 600;
    font-size: 32/16+em;
    line-height: 122%;
    letter-spacing: 0.5/32+em;
    margin-bottom: 117/16+rem;
    width: 696/16+rem;
    max-width: 100%;
    position: relative;

    @media screen and (max-width: $tablet) {
      font-size: 22/16+em;
    }

    &:before, &:after {
      content: '';
      width: 236/16+rem;
      height: 236/16+rem;
      border-radius: 50%;
      border-width: 30/16+rem;
      border-color: $color-secondary transparent transparent transparent;
      border-style: solid;
      position: absolute;
      display: block;

      @media screen and (max-width: $tablet) {
        width: 112/16+rem;
        height: 112/16+rem;
        border-width: 20px;
      }
    }

    &:before {
      top: -80/16+rem;
      left: -80/16+rem;
      transform: rotate(-45deg);

      @media screen and (max-width: $tablet) {
        top: -4.6rem;
        left: -1.4rem;
      }
    }

    &:after {
      bottom: -64/16+rem;
      right: -64/16+rem;
      transform: rotate(135deg);

      @media screen and (max-width: $tablet) {
        bottom: -4rem;
        right: -1.4rem;
      }
    }
  }

  .author {
    color: #fff;

    &__name {
      font-weight: 600;
      font-size: 18/16+em;
      line-height: 156%;
    }

    &__position {
      font-size: 14/16+em;
      line-height: 200%;
    }
  }
}