.slider-item {
  border-radius: 20px;
  display: flex !important;
  overflow: hidden;
  height: 100%;

  @media screen and (max-width: $laptop-s) {
    flex-direction: column;
  }

  &__left {
    background: $color-primary;
    color: #fff;
    padding: 50/16+em;
    flex-shrink: 0;

    @media screen and (min-width: $laptop-s + 1) {
      width: 45%;
    }

    @media screen and (min-width: $tablet) and (max-width: $laptop-s) {
      height: 50%;
    }

    @media screen and (max-width: $tablet) {
      flex-grow: 1;
      padding: $padding-inner-mobile;
    }
  }

  &__title {
    font-weight: 600;
    font-size: 45/16+em;
    line-height: 104%;
    letter-spacing: -1/48+em;
    margin-bottom: 74/48+em;
    margin-top: 0;

    @media screen and (max-width: $laptop-m) {
      font-size: 2.5em;
    }

    @media screen and (max-width: $tablet) {
      font-size: 2em;
      margin-bottom: 1.5rem;
    }
  }

  .plain-text {
    color: #fff;
    opacity: 1;

    &:not(:last-child) {
      margin-bottom: 78/16+rem;

      @media screen and (max-width: $tablet) {
        margin-bottom: 1.5rem;
      }
    }
  }

  &__right {
    flex-grow: 1;

    @media screen and (min-width: $tablet) and (max-width: $laptop-s) {
      height: 50%;
    }

    @media screen and (max-width: $tablet) {
      flex-grow: unset;
    }

    img {
      width: 100%;
      height: 100%;
      @include object-fit(cover);
      background: #67abcb;
    }
  }
}

.slider-outer {
  position: relative;

  .counter {
    position: absolute;
    z-index: 1;
    right: 102/16+rem;
    bottom: 27/16+rem;
    color: #f5f5f5;
    font-size: 14/16+em;
    text-align: center;
    letter-spacing: 0.5/14+em;
    background: rgba(0, 0, 0, .7);
    border-radius: 120px;
    padding: 6/14+em 19/14+em;

    @media screen and (max-width: $tablet) {
      display: none;
    }
  }
  
  .slick-slider {
    @media screen and (max-width: $tablet) {
      padding-bottom: 0;

      .slick-list {
        overflow: visible;
      }
    }
  }
  
  .slick-arrow {
    @media screen and (max-width: $tablet) {
      display: none !important;
    }
  }
  
  .slick-slide {
    @media screen and (max-width: $tablet) {
      width: calc(100vw - 2rem);
      padding-right: 1.25rem;
    }
  }
}