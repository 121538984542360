
@font-face {
  font-family: 'Gill Sans Nova';
  src: url('/assets/fonts/GillSansNova/GillSansNova-Medium.eot');
  src: local('Gill Sans Nova Medium'), local('GillSansNova-Medium'),
  url('/assets/fonts/GillSansNova/GillSansNova-Medium.eot?#iefix') format('embedded-opentype'),
  url('/assets/fonts/GillSansNova/GillSansNova-Medium.woff2') format('woff2'),
  url('/assets/fonts/GillSansNova/GillSansNova-Medium.woff') format('woff'),
  url('/assets/fonts/GillSansNova/GillSansNova-Medium.ttf') format('truetype');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'Gill Sans Nova';
  src: url('/assets/fonts/GillSansNova/GillSansNova-Light.eot');
  src: local('Gill Sans Nova Light'), local('GillSansNova-Light'),
  url('/assets/fonts/GillSansNova/GillSansNova-Light.eot?#iefix') format('embedded-opentype'),
  url('/assets/fonts/GillSansNova/GillSansNova-Light.woff2') format('woff2'),
  url('/assets/fonts/GillSansNova/GillSansNova-Light.woff') format('woff'),
  url('/assets/fonts/GillSansNova/GillSansNova-Light.ttf') format('truetype');
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: 'Gill Sans Nova';
  src: url('/assets/fonts/GillSansNova/GillSansNova-Bold.eot');
  src: local('Gill Sans Nova Bold'), local('GillSansNova-Bold'),
  url('/assets/fonts/GillSansNova/GillSansNova-Bold.eot?#iefix') format('embedded-opentype'),
  url('/assets/fonts/GillSansNova/GillSansNova-Bold.woff2') format('woff2'),
  url('/assets/fonts/GillSansNova/GillSansNova-Bold.woff') format('woff'),
  url('/assets/fonts/GillSansNova/GillSansNova-Bold.ttf') format('truetype');
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: 'Gill Sans Nova';
  src: url('/assets/fonts/GillSansNova/GillSansNova-Book.eot');
  src: local('Gill Sans Nova Book'), local('GillSansNova-Book'),
  url('/assets/fonts/GillSansNova/GillSansNova-Book.eot?#iefix') format('embedded-opentype'),
  url('/assets/fonts/GillSansNova/GillSansNova-Book.woff2') format('woff2'),
  url('/assets/fonts/GillSansNova/GillSansNova-Book.woff') format('woff'),
  url('/assets/fonts/GillSansNova/GillSansNova-Book.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Gill Sans Nova';
  src: url('/assets/fonts/GillSansNova/GillSansNova-ExtraBold.eot');
  src: local('Gill Sans Nova ExtraBold'), local('GillSansNova-ExtraBold'),
  url('/assets/fonts/GillSansNova/GillSansNova-ExtraBold.eot?#iefix') format('embedded-opentype'),
  url('/assets/fonts/GillSansNova/GillSansNova-ExtraBold.woff2') format('woff2'),
  url('/assets/fonts/GillSansNova/GillSansNova-ExtraBold.woff') format('woff'),
  url('/assets/fonts/GillSansNova/GillSansNova-ExtraBold.ttf') format('truetype');
  font-weight: 800;
  font-style: normal;
}

@font-face {
  font-family: 'Gill Sans Nova';
  src: url('/assets/fonts/GillSansNova/GillSansNova-SemiBold.eot');
  src: local('Gill Sans Nova SemiBold'), local('GillSansNova-SemiBold'),
  url('/assets/fonts/GillSansNova/GillSansNova-SemiBold.eot?#iefix') format('embedded-opentype'),
  url('/assets/fonts/GillSansNova/GillSansNova-SemiBold.woff2') format('woff2'),
  url('/assets/fonts/GillSansNova/GillSansNova-SemiBold.woff') format('woff'),
  url('/assets/fonts/GillSansNova/GillSansNova-SemiBold.ttf') format('truetype');
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: 'icomoon';
  src:  url('/assets/fonts/icoMoon/icomoon.eot?bjvcid');
  src:  url('/assets/fonts/icoMoon/icomoon.eot?bjvcid#iefix') format('embedded-opentype'),
  url('/assets/fonts/icoMoon/icomoon.ttf?bjvcid') format('truetype'),
  url('/assets/fonts/icoMoon/icomoon.woff?bjvcid') format('woff'),
  url('/assets/fonts/icoMoon/icomoon.svg?bjvcid#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="icon-"], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-printer:before {
  content: "\e91b";
}
.icon-youtube:before {
  content: "\e91a";
}
.icon-lock:before {
  content: "\e915";
}
.icon-lock-locked:before {
  content: "\e916";
}
.icon-question-mark:before {
  content: "\e917";
}
.icon-settings:before {
  content: "\e918";
}
.icon-user:before {
  content: "\e919";
}
.icon-calendar:before {
  content: "\e914";
}
.icon-play:before {
  content: "\e913";
}
.icon-chevron-right-thin:before {
  content: "\e90f";
}
.icon-chevron-down-thin:before {
  content: "\e910";
}
.icon-chevron-left-thin:before {
  content: "\e911";
}
.icon-chevron-up-thin:before {
  content: "\e912";
}
.icon-close:before {
  content: "\e90e";
}
.icon-phone:before {
  content: "\e900";
}
.icon-search:before {
  content: "\e901";
}
.icon-telegram:before {
  content: "\e902";
}
.icon-user-add:before {
  content: "\e903";
}
.icon-chevron-down-2:before {
  content: "\e904";
}
.icon-chevron-up:before {
  content: "\e905";
}
.icon-chevron-left:before {
  content: "\e906";
}
.icon-chevron-right:before {
  content: "\e907";
}
.icon-chevron-down:before {
  content: "\e908";
}
.icon-comment:before {
  content: "\e909";
}
.icon-eye:before {
  content: "\e90a";
}
.icon-facebook:before {
  content: "\e90b";
}
.icon-instagram:before {
  content: "\e90c";
}
.icon-login:before {
  content: "\e90d";
}
