//Свойство Object-fit
@mixin object-fit($fit: fill, $position: null){
  -o-object-fit: $fit;
  object-fit: $fit;
  @if $position {
    -o-object-position: $position;
    object-position: $position;
    font-family: 'object-fit: #{$fit}; object-position: #{$position}';
  } @else {
    font-family: 'object-fit: #{$fit}';
  }
}

@mixin col($col, $space: null) {
  display: flex;
  flex-wrap: wrap;

  &>* {
    width: 100%/$col;
  }

  @if ($space) {
    margin: -$space;

    &>* {
      padding: $space;
    }
  }
}

@mixin col-width($a, $b) {
  width: calc(100%*#{$b}/#{$a});
}

@mixin radial-graph($width, $border-width, $font-size) {
  .radial-graph,
  .shape,
  .mask,
  .fill {
    width: $width/16+em;
    height: $width/16+em;
    border-radius: 50%;
  }

  .shape {
    //transform: rotate(90deg);
  }

  .shape,
  .mask,
  .fill {
    position: absolute;
    background-color: $color-primary;
  }

  .mask,
  .fill {
    -webkit-backface-visibility: hidden;
    transition: transform 1s;
    border-radius: 50%;
  }

  .mask {
    clip: rect(0px, $width/16+rem, $width/16+rem, $width/32+em);
  }
  .fill {
    clip: rect(0px, $width/32+em, $width/16+rem, 0px);
    background-color: $color-secondary;
  }

  .cutout {
    margin-left: $border-width/16+em;
    margin-top: $border-width/16+em;
    width: ($width - $border-width*2)/16+em;
    height: ($width - $border-width*2)/16+em;
    position: absolute;
    background-color: white;
    border-radius: 50%;

    .radial-graph__value {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      font-weight: 500;
      font-size: $font-size/16+em;
      letter-spacing: 0.1/70+em;
      color: $color-primary;
    }
  }
}