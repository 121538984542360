.site-map {
  padding-bottom: 23/16+rem;
  margin-bottom: 23/16+rem;
  border-bottom: 1px solid #323232;

  &__title {
    margin-top: 0;
    font-size: 14/16+em;
    margin-bottom: 10/16+rem;
    font-weight: 500;
  }

  ul {
    list-style: none;
    margin: 0;
    padding: 0;
    
    li {
      &:not(:last-child) {
        margin-bottom: 5/16+em;
      }

      a, span {
        font-size: 12/16+em;
        opacity: .4;
        transition: .3s;
      }

      a:hover {
        opacity: 1;
      }
    }
  }
}