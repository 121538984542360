.header {
  padding-top: 24/16+em;
  position: relative;
  z-index: 99;
  //margin-bottom: 40/16+em;

  @media screen and (max-width: $laptop-s) {
    padding-top: 0;
  }

  .header-dropdown {
    position: relative;

    &__title {
      display: flex;
      align-items: center;
      cursor: pointer;
      transition: .3s;

      i.icon-chevron-down-2 {
        font-size: 10/16+em;
        margin-left: .25em;
        transition: .3s;
        display: inline-block;
      }

      &:hover {

      }
    }

    &__desc {
      position: absolute;
      top: 100%;
      left: 0;
      z-index: 1;
      transform: translateY(-.5em);
      opacity: 0;
      visibility: hidden;
      transition: .3s;
      width: 100%;
      max-height: 100vh;
    }

    &--regular {
      .header-dropdown__desc {
        background: #fff;
        padding: 15/16+em;
        margin-top: 10/16+em;
        box-shadow: $box-shadow;
        border-radius: 10px;

        a {
          padding: .5em 1em;
          display: block;
          transition: .3s;

          &:hover {
            opacity: .75;
          }
        }
      }
    }

    &.opened {
      .header-dropdown__desc {
        transform: translateY(0);
        opacity: 1;
        visibility: visible;
      }

      .header-dropdown__title i.icon-chevron-down-2 {
        transform: rotate(180deg);
      }
    }

    &--entrance {
      .header-dropdown__desc {
        background: #fff;
        padding-top: 31/16+em;
        min-height: 10em;
        background: none;
        min-width: 394/16+em;
        width: unset;
        right: -2.5em;
        left: auto;

        @media screen and (max-width: $laptop-m) {
          right: 0;
        }

        .comic-bubble {
          filter: drop-shadow(0px 6px 15px rgba(0, 0, 0, 0.14));
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;

          &:before {
            content: '';
            width: 3em;
            height: 3em;
            display: block;
            position: absolute;
            top: 0;
            right: 5.6em;
            background: #fff;
            transform: rotate(45deg) skew(13deg, 13deg);
            margin-top: 1.1em;

            @media screen and (max-width: $laptop-m) {
              right: 3.5em;
            }
          }

          &:after {
            content: '';
            background: #fff;
            height: calc(100% - 31em/16);
            left: 0;
            width: 100%;
            bottom: 0;
            position: absolute;
            border-radius: 20px;
          }

          &+div {
            padding: 40/16+em 30/16+em 30/16+em;
            position: relative;
            z-index: 1;
          }
        }

        .buttons {
          ul {
            flex-wrap: nowrap;
          }
        }

        .btn, .ghost-btn {
          min-width: 12em;
        }
      }
    }
  }

  &-top {
    margin-bottom: 18/16+em;

    @media screen and (max-width: $laptop-s) {
      margin-bottom: 0;
    }

    .container {
      display: flex;
      align-items: center;
    }

    .logo {
      display: flex;
      align-items: center;

      img {
        width: 145/16+em;
        margin-right: 25/16+em;

        @media screen and (max-width: $laptop-s) {
          width: 103/16+em;
          margin-right: 0;
        }
      }

      span {
        line-height: 150%;
        color: $color-primary;

        @media screen and (max-width: $laptop-s) {
          color: $color-secondary;
          font-size: 8/16+em;
          text-align: center;
        }
      }
    }

    .language {
      margin-left: auto;
      margin-right: 30/16+rem;

      @media screen and (max-width: $laptop-s) {
        display: flex;
        margin-right: auto;
      }

      .header-dropdown__title {
        padding: 7/16+em 15/16+em;
        border-radius: 20/16+em;

        @media screen and (max-width: $laptop-s) {
          background: $color-primary;
          margin-right: .25em;
          flex-shrink: 0;
        }

        img {
          width: 13/16+em;
          margin-right: 11/16+em;
        }

        span {
          color: $color-primary;
          font-size: 14/16+em;
          margin-right: 5/14+em;
          font-weight: 600;

          @media screen and (max-width: $laptop-s) {
            color: #fff;
          }
        }

        &:hover {
          background: #DFE3E8;
        }

        i {
          font-size: 11/16+em;
          margin-top: 2/11+em;

          @media screen and (max-width: $laptop-s) {
            display: none;
          }
        }
      }

      .header-dropdown__desc {
        overflow: hidden;
        margin-top: 10/16+em;
        border-radius: 1.25em;
        box-shadow: $box-shadow;
        background: #fff;

        @media screen and (max-width: $laptop-s) {
          position: relative;
          opacity: 1;
          visibility: visible;
          box-shadow: none;
          border-radius: 0;
          margin-top: 0;
          transform: none;
          display: flex;
        }

        a {
          display: flex;
          align-items: center;
          padding: .5em 1em;
          transition: .3s;

          @media screen and (max-width: $laptop-s) {
            padding: 7/16+em 15/16+em;
            background: #dfe3e8;
            border-radius: 20/16+em;
            flex-shrink: 0;

            &:not(:last-child) {
              margin-right: .25em;
            }
          }

          &:hover {
            background: #DFE3E8;
          }

          img {
            width: 13/16+em;
            margin-right: 11/16+em;
          }

          span {
            color: $color-primary;
            font-size: 14/16+em;
            margin-right: 5/14+em;
            font-weight: 600;
          }
        }
      }

      &.opened {
        .header-dropdown__title {
          background: #DFE3E8;
        }
      }
    }

    .search-btn {
      font-size: 20/16+em;
      color: $color-primary;
      transition: .3s;
      margin-right: 45/16+rem;

      &:hover {
        color: $color-primary-hover;
      }
    }
  }

  &-middle {
    padding-bottom: 18/16+em;
    border-bottom: 1px solid #E4E4E4;

    @media screen and (max-width: $laptop-s) {
      padding-bottom: 0;
      border-bottom: 0;
    }

    .container {
      display: flex;
      align-items: center;

      @media screen and (max-width: $laptop-s) {
        justify-content: center;
        padding-top: .5em !important;
      }
    }

    .phone {
      font-weight: bold;
      font-size: 18/16+em;
      line-height: 27/18+em;
      letter-spacing: 1/18+em;
      margin-right: 18/18+em;
    }

    .link {
      font-weight: 600;
    }

    .socials {
      margin-left: auto;

      @media screen and (max-width: $laptop-s) {
        margin-left: unset;
      }
    }
  }

  &-bottom {
    padding: 23/16+em 0;

    @media screen and (max-width: $laptop-s) {
      padding-top: 0;
    }

    .menu ul {
      justify-content: space-between;
    }
  }

  .buttons ul {
    align-items: center;
  }

  .header-link {
    font-weight: 600;
    font-size: 10/16+em;
    text-align: center;
    letter-spacing: 0.35/10+em;
    text-transform: uppercase;
    color: #636B78;
    transition: .3s;
    padding: .5em;
    display: block;

    &:hover {
      color: #3d414b;
    }
  }

  .mobile-header {
    box-shadow: 0px 4px 18px rgba(0, 0, 0, 0.03);
    background: #fff;
    position: fixed;
    top: 0;
    right: 0;
    left: 0;

    .container {
      display: flex;
      align-items: center;
      height: 60/16+em;
      justify-content: space-between;
      max-width: unset;

      &>div {
        flex-grow: 1;
        flex-basis: 0;
      }
    }

    .logo {
      display: flex;
      align-items: center;
      flex-direction: column;

      @media screen and (min-width: $tablet) {
        font-size: 1.25em;
      }

      img {
        width: 103/16+em;
      }

      span {
        font-size: 8/16+em;
        text-align: center;
        color: $color-primary;
      }
    }

    &__right {
      text-align: right;
      white-space: nowrap;

      a {
        color: #C6CEDD;
        font-size: 1.25em;

        &:not(:last-child) {
          margin-right: 26/16+rem;

          @media screen and (max-width: 340px) {
            margin-right: 1rem;
          }
        }
      }
    }
  }

  .header-inner {
    @media screen and (max-width: $laptop-s) {
      position: fixed;
      top: 60/16+em;
      background: #fff;
      width: 400/16+em;
      max-width: 100%;
      left: 0;
      z-index: 99;
      height: calc(100vh - 60em/16);
      border-top: 1px solid $light-gray;
      box-shadow: 0 4px 18px rgba(0, 0, 0, 0.03);
      overflow-y: auto;
      display: none;
      animation: fadeDown forwards .3s;
      -webkit-overflow-scrolling: touch;
      padding-bottom: 4rem;

      @keyframes fadeDown {
        0% {
          transform: translateY(-10px);
          opacity: 0;
        }
        100% {
          transform: translateY(0);
          opacity: 1;
        }
      }

      .container {
        padding-top: 1rem;
        padding-bottom: 1rem;
      }

      &.opened {
        display: block;
      }
    }

    @media screen and (max-width: $tablet) {
      width: 100%;
    }
  }

  .header-search {
    width: 100%;
    border-bottom: 1px solid $light-gray;
    display: flex;
    align-items: center;
    margin-bottom: 40/16+em;

    i {
      font-size: 13/16+em;
      margin-right: 10/16+em;
    }

    input {
      flex-grow: 1;
      border: none;
      -webkit-appearance: none;
      padding: 0.7rem 0;
      font-weight: 500;
      outline: none !important;
      font-family: inherit;
    }
  }
}

#auth-modal {
  .buttons {
    ul {
      @media screen and (max-width: $tablet) {
        justify-content: center;
      }
    }
  }
}