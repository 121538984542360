.logo-right {
  display: flex;
  border-radius: 20px;
  overflow: hidden;
  box-shadow: $hard-shadow;

  @media screen and (max-width: $laptop-s) {
    flex-direction: column-reverse;
  }

  .blue-block {
    border-radius: 0;

    @media screen and (min-width: $tablet + 1) {
      padding: 50/16+em;
    }
  }

  &__img {
    flex-shrink: 0;
    width: 430/16+em;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 50/16+em;

    @media screen and (max-width: $laptop-s) {
      width: 100%;
    }

    img {
      width: 307/16+em;
    }
  }

  .plain-text {
    @media screen and (min-width: $tablet + 1) {
      font-size: 1em;
    }
  }
}