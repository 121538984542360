.card {
  border: 1px solid $light-gray;
  display: flex;
  border-radius: 12px;
  overflow: hidden;
  position: relative;

  @media screen and (max-width: $tablet) {
    flex-direction: column;
  }

  &:not(:last-child) {
    margin-bottom: 20/16+rem;
  }

  &__body {
    flex-grow: 1;
  }

  &__attribute {
    flex-shrink: 0;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
  }

  &__button {
    flex-shrink: 0;
    font-size: 12/16+em;
    color: $color-primary;
    text-transform: uppercase;
    font-weight: 600;
    text-align: center;
    letter-spacing: 0.35/12+em;
    display: flex;
    align-items: center;
    transition: .3s;
    justify-content: center;

    &:hover {
      color: $color-primary-hover;
      background: $extra-light-gray;
    }
  }

  &>* {
    padding: 20/16+rem;

    &:not(:last-child) {
      @media screen and (min-width: $tablet + 1) {
        border-right: 1px solid $light-gray;
      }

      @media screen and (max-width: $tablet) {
        border-bottom: 1px solid $light-gray;
      }
    }
  }

  .plain-text {
    color: #000;
    font-size: 1em;
  }

  &__year {
    font-weight: 600;
    font-size: 33/16+em;
    line-height: 110%;
    letter-spacing: -0.1/33+em;
    margin-bottom: 15/16+rem;
  }

  &--vertical {
    flex-direction: column;
    height: 100%;

    .card__body {
      display: flex;
      flex-direction: column;

      .top-auto {
        margin-top: auto;
      }
    }

    &>* {
      &:not(:last-child) {
        border-right: none;
        border-bottom: 1px solid $light-gray;
      }
    }
  }

  &__zone {
    color: $color-tertiary;
    font-size: 18/16+em;

    &:not(:last-child) {
      margin-bottom: 6/16+rem;
    }
  }

  &__name {
    margin-top: 0;
    font-weight: bold;
    font-size: 1.25em;
    margin-bottom: 0;

    &:not(:last-child) {
      margin-bottom: 10/16+rem;
    }

    &+.card__name {
      margin-top: -0.3em;
    }
  }

  &__status {
    font-weight: bold;
    font-size: 18/16+em;

    &--green {
      color: #38B756;
    }

    &--yellow {
      color: #DA9D28;
    }

    &--red {
      color: #a40000;
    }

    &:not(:last-child) {
      margin-bottom: 7/18+rem;
    }
  }

  &__link {
    position: absolute;
    z-index: 1;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }

  &__section--bottom2 {
    padding: 0;
    display: flex;
  }

  &--announcement {
    transition: .3s;

    @media screen and (min-width: $laptop-s + 1) {
      flex-direction: row;
    }

    @media screen and (max-width: $laptop-s) {
      flex-direction: column;
    }

    &>div {
      padding: 1.25em;

      @media screen and (max-width: $laptop-s) {
        border-right: none;
      }

      &:first-child {
        align-self: center;
        flex-grow: 1;
        border-right: none;

        @media screen and (max-width: $tablet) {
          align-items: flex-start;
          width: 100%;
        }
      }
    }

    .date {
      padding: 20/16+rem;
      width: 50%;
      display: flex;
      flex-direction: column;

      &:not(:last-child) {
        border-right: 1px solid $border-color;
      }

      i {
        font-size: 25/16+em;
        margin-bottom: .5em;
        color: #8b9cb2;
      }

      &__title {
        font-size: 14/16+em;
        color: #8b9cb2;
        margin-bottom: 7/14+em;
      }

      &__value {
        font-weight: bold;
        font-size: 18/16+em;
      }
    }

    &:hover {
      box-shadow: 0 5px 10px rgba(0,0,0,.1);
    }

    .card__section--bottom2 {
      background: #F2F4F6;
      border-top: none !important;
      border-right: none;
      flex-shrink: 0;
      margin-top: 0;

      @media screen and (min-width: $laptop-s + 1) {
        width: 506/16+rem;
      }
    }

    .card__name {
      line-height: 142%;
    }

    .card__status {
      font-weight: 500;

      span {
        font-weight: normal;
        color: $color-tertiary;
      }
    }

    .date {
      @media screen and (max-width: $tablet) {
        padding: 0;
      }
    }

    .date:not(:last-child) {
      @media screen and (max-width: $tablet) {
        padding-right: 1em;
        margin-right: 1em;
      }
    }

    .date__value {
      font-size: 24/16+em;
      font-weight: 500;
    }
  }

  &--announcement-detail {
    padding: 50/16+em;
    flex-direction: column;

    @media screen and (max-width: $tablet) {
      padding: $padding-inner-mobile;
    }

    &>*:not(:last-child) {
      border-right: none;
    }

    .card__section {
      padding: 0;
      border-top: none;

      &:not(:last-child) {
        margin-bottom: 20/16+em;
      }
    }

    .card__section--bottom2 {
      align-items: flex-end;
      margin-top: 40/16+em;
      justify-content: space-between;

      @media screen and (max-width: $laptop-s) {
        flex-direction: column-reverse;
        align-items: flex-start;
      }
    }

    .dates {
      display: flex;

      @media screen and (max-width: $laptop-s) {
        margin-top: 2rem;
      }

      @media screen and (max-width: $tablet) {
        flex-direction: column;
        width: 100%;
      }
    }

    .date {
      padding: 20/16+rem;
      width: 50%;
      display: flex;
      flex-direction: column;

      &:not(:last-child) {
        border-right: 1px solid $border-color;
      }

      i {
        font-size: 25/16+em;
        margin-bottom: .5em;
        color: #8b9cb2;
      }

      &__title {
        font-size: 14/16+em;
        color: #8b9cb2;
        margin-bottom: 7/14+em;
      }

      &__value {
        font-weight: bold;
        font-size: 18/16+em;
      }
    }

    .date {
      padding: 0;
      width: unset;
      max-width: 158/14+em;

      &:not(:last-child) {
        padding-right: 2em;
        margin-right: 2em;

        @media screen and (max-width: $tablet) {
          padding-bottom: 1em;
          margin-bottom: 1em;
          border-right: none;
          border-bottom: 1px solid $border-color;
          padding-right: 0;
          margin-right: 0;
        }
      }

      &__value {
        font-size: 24/16+em;
        font-weight: 500;
      }

      @media screen and (max-width: $tablet) {
        max-width: unset;
      }
    }
  }

  &[href] {
    .card__name, .news-title {
      transition: .3s;
    }

    &:hover {
      .card__name, .news-title {
        color: $color-primary;
      }

      .card__button {
        color: $color-primary-hover;
        background: $extra-light-gray;
      }
    }
  }
}