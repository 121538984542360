.footer {
  background: #222222;
  padding: 66/16+em 0;
  color: #fff;

  @media screen and (max-width: $tablet) {
    padding: 48/16+em 0;
  }

  &-bottom {
    display: flex;
    justify-content: flex-end;
    align-items: center;

    @media screen and (max-width: $tablet) {
      flex-direction: column;
      align-items: flex-start;
    }

    .copyright {
      font-size: 10/16+em;
      text-align: right;
      opacity: 0.2;
      margin-right: 2rem;

      @media screen and (max-width: $tablet) {
        text-align: left;
        margin-right: unset;
        margin-bottom: 2rem;
      }
    }

    .socials {
      a:hover {
        color: #fff;
      }
    }
  }
}