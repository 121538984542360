.vacancies {
  .spoilers .spoiler__title>div {
    flex-grow: 1;

    @media screen and (max-width: $tablet) {
      flex-direction: column;
    }
  }

  @media screen and (max-width: $tablet) {
    .spoilers .spoiler__date {
      border-right: none;
      padding-bottom: 0;
    }

    .spoilers .spoiler__date {
      display: none;
    }
  }
}