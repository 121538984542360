.date-card {
  display: flex;

  &:not(:last-child) {
    padding-bottom: 60/16+rem;
    margin-bottom: 60/16+rem;
    border-bottom: 1px solid $light-gray;
  }

  &:last-child {
    margin-bottom: 90/16+rem;
  }

  &__value {
    flex-shrink: 0;
    font-weight: 600;
    font-size: 64/16+em;
    line-height: 110%;
    letter-spacing: -0.1/64+em;
    width: 200/64+em;
    margin-right: 20/16+rem;
    color: $color-primary;
  }

  &__desc {

  }
}