@media screen and (min-width: $tablet + 1) {
  $certification-padding: 50/16+em;

  .certification-grid1, .certification-grid2 {
    .row {
      margin-left: -$certification-padding;
      margin-right: -$certification-padding;

      &--multiline {
        margin: -$certification-padding;

        &>[class^='col-'] {
          padding: $certification-padding;
        }
      }

      &>[class^='col-'] {
        padding-left: $certification-padding;
        padding-right: $certification-padding;
      }
    }
  }
}

@media screen and (min-width: $tablet + 1) {
  $certification-padding2: 25/16+em;

  .certification-grid1, .certification-grid2 {
    .row {
      margin-left: -$certification-padding2;
      margin-right: -$certification-padding2;

      &--multiline {
        margin: -$certification-padding2;

        &>[class^='col-'] {
          padding: $certification-padding2;
        }
      }

      &>[class^='col-'] {
        padding-left: $certification-padding2;
        padding-right: $certification-padding2;
      }
    }
  }
}

.certification-grid1 {
  [class^='col-'] {
    &:first-child {
      @media screen and (min-width: $laptop-s + 1) {
        padding-right: 0;
      }
    }

    &:nth-of-type(2) {
      @media screen and (min-width: $tablet + 1) {
        border-right: 1px solid $light-gray;
      }

      @media screen and (max-width: $tablet) {
        border-bottom: 1px solid $light-gray;
      }
    }
  }
}

.certification-grid2 {
  margin-top: 80/16+em;

  [class^='col-'] {
    border-right: 1px solid $light-gray;
    
    @media screen and (min-width: $laptop-s + 1) {
      &:nth-of-type(3n + 3) {
        border-right: none;
      }
    }

    @media screen and (max-width: $laptop-s) {
      border-right: none;
    }
    
    @media screen and (max-width: $tablet) {
      &:not(:last-child) {
        border-bottom: 1px solid $light-gray;
      }
    }
  }
}