.slick-slider {
  position: relative;

  @media screen and (max-width: $tablet) {
    padding-bottom: 72/16+em;
  }

  .slick-arrow {
    font-size: 15/16+em;
    width: 54/16+em;
    height: 54/16+em;
    border-radius: 50%;
    display: flex !important;
    align-items: center;
    justify-content: center;
    background: #FFFFFF;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    color: $gray-text;
    transition: .3s;
    position: absolute;
    z-index: 2;
    top: 50%;
    transform: translateY(-50%);
    cursor: pointer;

    @media screen and (max-width: $tablet) {
      bottom: 0;
      top: unset;
      transform: none;
      width: 38/16+rem;
      height: 38/16+rem;
      color: $color-primary;
      border: 2px solid;
      box-shadow: none;
      background: none;
    }

    &:hover {
      color: $gray-text-hover;
      box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
    }

    &.icon-chevron-left {
      left: -27/16+em;

      @media screen and (max-width: $tablet) {
        left: unset;
        right: calc(50% + 10em / 16);
      }
    }

    &.icon-chevron-right {
      right: -27/16+em;

      @media screen and (max-width: $tablet) {
        right: unset;
        left: calc(50% + 10em / 16);
      }
    }

    &.slick-disabled {
      opacity: 0;
      pointer-events: none;
      visibility: hidden;
    }
  }
}

.slick-track {
  display: flex;

  .slick-slide {
    height: unset !important;

    & > div {
      height: 100%;
    }
  }
}

#news {
  @media screen and (min-width: $tablet + 1) {
    .slick-list {
      margin: -$padding;

      .slick-slide {
        padding: $padding;
      }
    }
  }

  .slick-arrow {
    top: 9em;

    @media screen and (max-width: $laptop-m) {
      top: 7em;
    }

    @media screen and (max-width: $laptop-m) {
      top: 8em;
    }

    @media screen and (max-width: $tablet) {
      top: unset;
      display: none !important;
    }
  }

  @media screen and (max-width: $tablet) {
    padding-bottom: 0;

    .slick-list {
      overflow: visible;
    }
  }

  .slick-slide {
    @media screen and (max-width: $tablet) {
      width: calc(100vw - 2rem);
      padding-right: 1.25rem;
    }
  }
}

#services {
  .slick-list {
    margin: -$padding;

    .slick-slide {
      padding: $padding;
    }
  }
}