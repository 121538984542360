.strategy-card {
  box-shadow: $box-shadow;
  border: 1px solid $light-gray;
  border-radius: 20px;
  background: #fff;
  padding: 30/16+em 30/16+em 79/16+em;
  height: 100%;

  &--mini {
    padding: 30/16+em;

    .strategy-card__text {
      font-size: 18/16+em;
    }
  }

  &--flat {
    padding: 0;
    border-radius: 0;
    border: none;
    box-shadow: none;
  }

  &__number {
    font-weight: 500;
    font-size: 18/16+em;
    line-height: 156%;
    letter-spacing: 0.5/18+em;
    color: $gray;
    margin-bottom: 10/16+em;
  }

  &__img {
    display: block;
    width: 100/16+em;
    height: 100/16+em;
    @include object-fit(contain);
    margin: 0 auto 27/16+em;
  }

  &__text {
    font-size: 20/16+em;
    line-height: 29/20+em;
    letter-spacing: 0.02em;
    color: #000000;
  }
}