.article-card {
  display: flex !important;
  flex-direction: column;
  height: 100%;

  &__image {
    padding-bottom: 230%/400*100;
    position: relative;
    margin-bottom: 14/16+em;
    border-radius: 12px;
    overflow: hidden;

    img {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      @include object-fit(cover);
    }
  }

  &__title {
    font-weight: 600;
    font-size: 20/16+em;
    line-height: 29/20+em;
    color: #000000;
    margin-top: 0;
    transition: .3s;

    &:not(:last-child) {
      margin-bottom: 20/16+rem;
    }
  }

  &__bottom {
    display: flex;
    justify-content: space-between;
    margin-top: auto;

    @media screen and (max-width: $tablet) {
      margin-top: unset;
    }
  }

  &__meta {
    color: $gray-text;
    font-size: 15/16+em;
    line-height: 22/15+em;
  }

  &__indicator {
    display: flex;
    align-items: center;
    color: $gray-text;

    i {
      margin-right: 6/16+em;
    }

    span {
      font-size: 15/16+em;
      line-height: 22/15+em;
    }
  }

  &:hover {
    .article-card__title {
      color: $color-primary-hover;
    }
  }

  &--inner {
    .article-card__meta {
      padding-bottom: 10/16+rem;
      border-bottom: 1px solid $light-gray;
    }
  }

  &--video {
    &:hover {
      .article-card__image {
        &:before {
          background: rgba(0,0,0,.5);
        }

        &:after {
          opacity: 1;
        }
      }
    }

    .article-card__image {
      position: relative;

      &:before {
        content: '';
        position: absolute;
        z-index: 1;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: rgba(0,0,0,.2);
        transition: .3s;
      }

      &:after {
        font-family: icomoon;
        content: "\e913";
        position: absolute;
        z-index: 2;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        font-size: 50/16+em;
        color: #fff;
        opacity: .75;
        transition: .3s;
      }
    }
  }
}