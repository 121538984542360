.alert {
  font-size: 14/16+em;
  margin: 18/14+em 0;
  padding: 20.5/14+em;

  ul {
    list-style: none;
    padding: 0;
    margin: 0;
  }

  &-success {
    color: #155724;
    background: #d4edda;
  }

  &-info {
    color: #004085;
    background: #cce5ff;
  }

  &-warning {
    color: #856404;
    background: #fff3cd;
  }

  &-danger {
    color: #721c24;
    background: #f8d7da;
  }
}