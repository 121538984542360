.items-group {
  background: #fff;
  box-shadow: $hard-shadow;
  border-radius: 20px;
  overflow: hidden;

  &:not(:last-child) {
    margin-bottom: 30/16+em;
  }

  &>.row {
    margin: 0;

    &>[class^="col-"]:not(.items-group__blue):not(.items-group__white) {
      padding: 0;

      &:not(:last-child) {
        .item {
          @media screen and (min-width: $tablet + 1) {
            border-right: 1px solid $light-gray;

            &.item--blue {
              border-right-color: rgba(255,255,255,.2);
            }
          }

          @media screen and (max-width: $tablet) {
            border-bottom: 1px solid $light-gray;

            &.item--blue {
              border-bottom-color: rgba(255,255,255,.2);
            }
          }
        }
      }

      @media screen and (max-width: $laptop-s) {
        .item {
          border-right: none;
        }
      }
    }
  }

  .item {
    padding: 20/16+em;
    height: 100%;
    display: flex;
    flex-direction: column;

    &:not(:last-child) {
      border-bottom: 1px solid $light-gray;
    }

    &.height-auto {
      height: auto;
    }

    &--blue {
      background: $color-primary;
      color: #fff;

      &:not(:last-child) {
        border-bottom-color: rgba(255,255,255,.2);
      }

      .plain-text {
        color: #fff;
      }
    }

    &__title {
      font-weight: 600;
      font-size: 18/16+em;
      letter-spacing: -0.1/18+em;
      margin-bottom: 24/16+em;
    }

    .stats {
      text-align: center;
    }

    &__hint {
      margin-top: 32/14+em;
      font-size: 14/16+em;
      line-height: 20/14+em;
      letter-spacing: 0.1/14+em;
    }

    .stats-item {
      &__value {
        font-weight: 600;
        font-size: 59/16+em;
        line-height: 110%;
        letter-spacing: -0.1/59+em;
      }

      &__unit {
        font-size: 1em;
        letter-spacing: 0.1/16+em;
      }
    }
  }

  .align-items-center {
    .item {
      justify-content: center;
    }
  }

  &--mission {
    .title-primary {
      @media screen and (max-width: $laptop-s) {
        margin-bottom: 1.875rem;
      }
    }

    &>.row {
      &>.items-group__white, &>.items-group__blue {
        padding: 50/16+em;

        @media screen and (max-width: $tablet) {
          padding: $padding-inner-mobile;
        }
      }

      &>.items-group__blue {
        background: $color-primary;
        color: #fff;

        .plain-text {
          color: #fff;
        }
      }

      &:not(:first-child) {
        margin-top: -50px;
      }
    }
  }
}