.gradient-card {
  padding: 50/16+em;
  border-radius: 20px;
  overflow: hidden;
  background: linear-gradient(90deg, #234675 50%, rgba(35, 70, 117, 0.38) 86.9%);
  color: #fff;
  position: relative;

  @media screen and (max-width: $tablet) {
    padding: $padding-inner-mobile;
  }

  .title-primary {
    color: #fff;
    margin-bottom: 30/16+rem;
  }

  .plain-text {
    color: #fff;
  }

  &__bg {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    @include object-fit(cover);
    z-index: -1;
  }
}