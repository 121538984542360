.search-group {
  border: 1px solid $light-gray;
  border-radius: 20px;
  overflow: hidden;
  box-shadow: $box-shadow;

  &__header {
    position: relative;
    border-bottom: 1px solid $light-gray;

    @media screen and (max-width: $tablet) {
      font-size: .75em;
    }

    i {
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      font-size: 1.5em;
      color: $color-primary;
      left: 24/24+em;
    }

    input {
      border: none;
      border-radius: 0;
      font-size: 20/16+em;
      font-weight: 500;
      height: 82/20+em;
      color: $gray;
      margin-left: 63/20+em;
      padding-right: 50/20+em;
      width: calc(100% - 63em/20);
      outline: none !important;
    }
  }

  &__body {
    padding: 50/16+rem;

    @media screen and (max-width: $tablet) {
      padding: $padding-inner-mobile;
    }
  }
}