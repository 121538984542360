.help {
  background: #F4F7FD;
  border-radius: 30px;
  display: flex;

  @media screen and (max-width: $laptop-s) {
    flex-direction: column;
    border-radius: 20px;
  }

  &__image {
    padding-left: 45/16+em;
    width: 458/16+em;
    max-width: 100%;
    align-self: flex-end;
    flex-shrink: 0;

    @media screen and (max-width: $laptop-s) {
      padding: 2em;
      width: unset;
      align-self: center;
    }

    @media screen and (max-width: $tablet) {
      padding: 1.25em;
    }

    img {
      display: block;
    }
  }

  &__desc {
    padding: 35/16+em 45/16+em 50/16+em 100/16+em;

    @media screen and (max-width: $laptop-s) {
      text-align: center;
      padding: 2em;
    }

    @media screen and (max-width: $tablet) {
      padding: 1.25em;
    }
  }

  .title-primary {
    margin-bottom: 30/16+rem;
  }

  .plain-text {
    margin-bottom: 10/16+rem;
  }

  &__phone {
    font-weight: 600;
    font-size: 24/16+em;
    line-height: 35/24+em;
    letter-spacing: 1/24+em;
    color: $dark-font;
    display: table;
    margin-bottom: 40/24+em;

    @media screen and (max-width: $laptop-s) {
      margin-left: auto;
      margin-right: auto;
    }
  }

  .btn {
    box-shadow: 5px 5px 30px rgba(37, 35, 117, 0.25);
  }
}