.filter {
  background: #fff;
  padding: 25/16+em 30/16+em;
  border-radius: 10px;
  border: 1px solid $light-gray;
  box-shadow: 0 6px 15px rgba(0,0,0,.14);
  margin-bottom: 60/16+em;

  .btn {
    min-width: unset;
  }

  @media screen and (max-width: $tablet) {
    padding: 1.25em;
  }

  & > .row {
    align-items: center;

    @media screen and (min-width: $laptop-s + 1) {
      width: calc(100% + #{$padding} * 2);
    }
    
    .col-auto {
      @media screen and (max-width: $laptop-s) {
        width: 100%;
      }
    }
  }

  &__inputs {
    flex-grow: 1;
    
    [class^="col-"] {
      @media screen and (max-width: $laptop-s) {
        width: 100%;
      }
    }
  }

  &--participants {
    .filter__inputs {
      .row {
        @media screen and (min-width: $laptop-s + 1) {
          flex-wrap: nowrap;

          .col-auto {
            &:nth-of-type(1) {
              width: 14.5%;
            }

            &:nth-of-type(2) {
              width: 13%;
            }

            &:nth-of-type(3) {
              width: 18.5%;
            }

            &:nth-of-type(4) {
              width: 22.5%;
            }

            &:nth-of-type(5) {
              width: 31.5%;
            }
          }
        }

        .col-auto {
          @media screen and (max-width: $laptop-s) {
            width: 100%;
          }
        }
      }
    }
  }

  &--applications {
    .filter__inputs {
      .row {
        @media screen and (min-width: $laptop-s + 1) {
          flex-wrap: nowrap;

          .col-auto {
            &:nth-of-type(1) {
              width: 20%;
            }

            &:nth-of-type(2) {
              width: 20%;
            }

            &:nth-of-type(3) {
              width: 20%;
            }

            &:nth-of-type(4) {
              width: 40%;
            }
          }
        }

        .col-auto {
          @media screen and (max-width: $laptop-s) {
            width: 100%;
          }
        }
      }
    }
  }
}