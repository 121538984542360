.plans-block {
  padding: 50/16+em;
  border-radius: 12px;
  overflow: hidden;
  background: url(/assets/img/plans-bg.png) center right no-repeat #B0DBDE;
  background-size: auto 100%;

  &>div {
    width: 690/16+em;
    max-width: 100%;
  }

  .title-primary {
    margin-bottom: 40/16+rem;
  }
}