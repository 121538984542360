.breadcrumbs {
  background-color: #EAEAEA;
  padding: 14/16+em 0;

  ul {
    list-style: none;
    padding-left: 0;
    margin-top: 0;
    margin-bottom: 0;
    display: flex;
    flex-wrap: wrap;

    li {
      font-size: 18/16+em;
      //font-weight: bold;

      &:not(:last-child ) {
        &:after {
          content: "\e907";
          font-family: icomoon;
          margin: 0 22/14+em;
          color: $color-primary;
          font-size: 0.6em;
        }
      }

      a {
        color: $color-primary;
        transition: .3s;

        &:hover {
          color: $color-primary-hover;
        }
      }

      span {
        color: $color-secondary;
      }
    }
  }
}