.contacts {
  display: flex;
  border-radius: 20px;
  overflow: hidden;
  box-shadow: $box-shadow;
  border: 1px solid $light-gray;
  flex-wrap: wrap;

  &>div {
    width: 50%;
    flex-shrink: 0;

    @media screen and (max-width: $laptop-s) {
      width: 100%;
    }
  }

  .white-block {
    border-radius: 0;
    box-shadow: none;
    border: none;
  }

  .map {
    @media screen and (max-width: $laptop-s) {
      height: 270px;
    }
    
    &>* {
      height: 100% !important;
    }
  }
}