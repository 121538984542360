.buttons {
  ul {
    display: flex;
    flex-wrap: wrap;
    list-style: none;
    padding-left: 0;
    margin: -$padding;

    @media screen and (max-width: $tablet) {
      margin: -$padding-mobile;
      justify-content: flex-start;
    }

    li {
      padding: $padding;

      @media screen and (max-width: $tablet) {
        padding: $padding-mobile;
      }
    }
  }
}