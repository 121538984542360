body {
  .chosen-container {
    width: 100% !important;
    //text-transform: uppercase;
    font-size: 1.125em;
  }

  .chosen-container-single .chosen-single {
    border: 1px solid $border-color;
    border-radius: $border-radius;
    color: #333;
    background: #fff;
    padding: 0 12/18+em;
    height: 50/18+em;
    line-height: calc(50em/18 - 2px);
    box-shadow: inset 0 4px 4px rgba(0, 0, 0, 0.05);
    //white-space: normal;

    span {
      //white-space: normal;
      margin-right: 40px;
    }
  }

  .chosen-container-multi .chosen-choices {
    //white-space: nowrap;
    padding: 0;
    box-shadow: none;
    border: 1px solid $border-color;
    border-radius: 0;
    //min-height: 51px;
    overflow-x: auto;
    cursor: pointer;
  }

  .chosen-container-single .chosen-single div b {
    background: none;
  }

  .chosen-container-multi .chosen-choices li.search-field {
    box-shadow: none;
    border-radius: 0;
    border: none;
    height: auto;
    line-height: normal;
    padding: 5/16+em 10/16+em;
    color: #333;
    float: none;
    display: inline-block;
    margin: 0;
    vertical-align: top;

    &:not(last-child) {

    }
  }

  .chosen-container-multi .chosen-choices {
    display: flex;
    flex-wrap: wrap;
    padding: 1.5/12.5+em;
    align-items: center;
  }

  .chosen-container-multi .chosen-choices li.search-choice {
    display: inline-flex;
    margin: 1.5/12.5+em;
    border: none;
    align-items: center;
    padding: 8.5/12.5+em 10/12.5+em 8.5/12.5+em 40/12.5+em;

    span {
      margin-right: 20/12.5+em;
    }
  }

  .chosen-container-multi .chosen-choices li.search-choice .search-choice-close {
    position: relative;
    font-size: 10/12.5+em;
    top: auto;
    right: auto;
    width: auto;
    height: auto;
    background: none;
    display: flex;
    line-height: 1;
    transition: .3s;

    &:hover {
      opacity: .9;
    }

    &:before {
      content: "\e901";
      font-family: 'icomoon';
    }
  }

  .chosen-container-multi .chosen-choices li.search-field {
    border-right: none !important;

    &:first-child {
      width: 100%;

      input[type=text] {
        width: 100% !important;
      }
    }
  }

  .chosen-container-multi .chosen-choices li.search-field input[type=text] {
    height: auto;
    margin: 0;
    line-height: 1.1;
    display: block;
    color: #aaa;
    background: #fff;
    font-family: inherit;
  }

  .chosen-container.chosen-container-single.chosen-container-active.chosen-with-drop > a,
  .chosen-container.chosen-container-multi.chosen-with-drop.chosen-container-active .chosen-choices {
    border-color: $color-primary;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
  }

  .chosen-container.chosen-container-active.chosen-with-drop .chosen-drop {
    border-color: $color-primary;
    border-bottom-left-radius: $border-radius;
    border-bottom-right-radius: $border-radius;
  }

  .chosen-container-active.chosen-with-drop .chosen-single {
    background: #fff;
    //border-top-right-radius: 0;
    //border-top-left-radius: 0;
  }

  .chosen-container-active.chosen-with-drop .chosen-single, .chosen-container.chosen-with-drop .chosen-drop {
    border-color: #ccc;
    border-width: 1px;
  }

  .chosen-container-single .chosen-single span {
    //overflow: visible;
  }

  .chosen-container-single .chosen-single div b {
    position: absolute;
    top: 50%;
    transform: translateY(-50%) translateX(-50%);
    left: 50%;
    color: $color-primary;
    width: auto;
    height: auto;
    font-size: 0.65em;
    font-weight: normal;

    &:after {
      content: "\e908";
      font-family: 'icomoon';
    }
  }

  .chosen-container-active.chosen-with-drop .chosen-single div b {
    &:after {
      content: "\e905";
    }
  }

  .chosen-container .chosen-results {
    font-size: 1em !important;
  }

  .chosen-container-single .chosen-single div {
    position: absolute;
    right: 0;
    top: 0;
    height: 100%;
    width: 60px;
  }

  .chosen-container-single .chosen-drop {
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
    overflow: hidden;
    margin-top: 0;

    .hidden {
      display: none !important;
    }
  }

  .chosen-container .chosen-results {
    padding: 0;
    margin: 0;

    &::-webkit-scrollbar-track {
      background-color: #fbfbfb;
    }

    &::-webkit-scrollbar {
      width: 4px;
      background-color: #fbfbfb;
      //border-radius: 5px;
    }

    &::-webkit-scrollbar-thumb {
      background-color: $color-primary;
      border-radius: 0;
    }
  }

  .chosen-container .chosen-results li {
    line-height: normal;
    padding: 8/16+em 12/16+em;
  }

  .chosen-container-single .chosen-default {
    color: #aaa;
  }

  .chosen-container .chosen-results li.highlighted {
    background: #f9f9f9;
    color: initial;
  }

  .chosen-container .chosen-results li.result-selected {
    background: $color-primary;
    color: #fff;
  }

  .chosen-container-single .chosen-search {
    //display: none;
    padding: 0;
    margin: 0;
    display: block;

    input[type=text] {
      margin: 0;
      border: none;
      border-bottom: 1px solid;
      border-color: $border-color;
      padding: 0 12/16+em;
      height: 36/16+em;
      padding-right: 3em;
      background: none;
      background: #fff;
      font-family: inherit;
    }

    &:after {
      content: "\e901";
      font-family: icomoon;
      display: block;
      top: 50%;
      transform: translateY(-50%);
      width: 1.5em;
      position: absolute;
      right: 1.3em;
      font-size: 0.85em;
      text-align: right;
      color: $gray-text;
    }
  }

  .no-search + .chosen-container-single .chosen-search {
    display: none;
  }
}