html {
  font-size: 16px;
  font-family: 'Gill Sans Nova', sans-serif;

  body {
    margin: 0;
    color: #1F2024;

    &.no-scroll {
      overflow: hidden;
      position: fixed;
      height: 100vh;
      left: 0;
      top: 0;
      right: 0;
    }
  }
}

*, ::after, ::before {
  box-sizing: border-box;
}

p {
  margin-top: 0;
}

a {
  color: inherit;
  text-decoration: none;

  &:focus {
    outline: none;
  }
}

img {
  max-width: 100%;
}

.main-wrapper {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  overflow: hidden;

  footer {
    margin-top: auto;
  }
}