.menu {
  @media screen and (max-width: $laptop-s) {
    margin: 1em 0;
  }

  ul {
    list-style: none;
    display: flex;
    align-items: center;
    padding-left: 0;
    margin: 0;
    margin: -14.5/16+em;

    @media screen and (max-width: $laptop-s) {
      flex-direction: column;
      align-items: flex-start;
    }

    li {
      padding: 14.5/16+em;

      @media screen and (min-width: $laptop-s) and (max-width: $laptop-m) {
        padding: .5em;
      }

      a {
        transition: .3s;
        font-weight: bold;
        font-size: 18/16+em;
        text-transform: uppercase;

        @media screen and (min-width: $laptop-s) and (max-width: 1350px) {
          font-size: .875em;
        }

        @media screen and (max-width: $laptop-s) {
          text-transform: unset;
        }

        &:hover {
          opacity: .75;
        }
      }

      &.active a {
        color: $color-primary;
      }

      &.dot {
        @media screen and (max-width: $laptop-s) {
          display: none;
        }

        &:before {
          content: '';
          width: 7/16+em;
          height: 7/16+em;
          display: block;
          border-radius: 50%;
          background: #ABBDD4;
        }
      }

      &.dropdown {
        position: relative;
        display: block;

        & > a {
          @media screen and (max-width: $laptop-s) {
            display: block;
            margin-bottom: 29/16+em;
          }
        }

        &:hover {
          .dropdown-menu {
            opacity: 1;
            transform: none;
            visibility: visible;
          }
        }

        @media screen and (min-width: $laptop-s + 1) {
          .dropdown-menu {
            position: absolute;
            z-index: 1;
            top: 100%;
            left: 0;
            background: #fff;
            box-shadow: 0 0 10px rgba(0, 0, 0, .1);
            //min-width: 15.25em;
            padding: 10/16+em;
            border-radius: 5px;
            flex-direction: column;
            align-items: flex-start;
            visibility: hidden;
            opacity: 0;
            transform: translateY(-.5em);
            transition: .3s;
            margin: 0;

            a {
              display: block;
              white-space: nowrap;
              text-transform: unset;
            }
          }

          &:last-child {
            .dropdown-menu {
              left: unset;
              right: 0;
            }
          }
        }
      }
    }
  }
}