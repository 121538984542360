.socials {
  ul {
    padding: 0;
    margin: -15/16+em;
    list-style: none;
    display: flex;

    li {
      padding: 15/16+em;

      &.divider {
        position: relative;

        &:after {
          content: '';
          display: inline-block;
          height: 24px;
          width: 1px;
          background: $color-tertiary;
          position: absolute;
          top: 50%;
          transform: translateY(-50%);
        }
      }
    }
  }

  a {
    color: $color-tertiary;
    transition: .3s;

    &:hover {
      color: $color-tertiary-hover;
    }
  }
}