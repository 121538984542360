.blue-block {
  background: $color-primary;
  color: #fff;
  padding: 50/16+em;
  border-radius: 20px;

  @media screen and (max-width: $tablet) {
    padding: $padding-inner-mobile;
  }

  .title-primary, .title-secondary {
    color: #fff;
    margin-bottom: 30/16+rem;
  }

  .plain-text {
    color: #fff;

    &:not(:last-child) {
      margin-bottom: 54/16+rem;
    }
  }
}