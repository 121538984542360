.business-card {
  box-shadow: $hard-shadow;
  border-radius: 20px;
  background: #fff;
  overflow: hidden;
  position: relative;
  height: 100%;
  display: flex;
  flex-direction: column;

  &__number {
    position: absolute;
    top: 20/16+rem;
    right: 30/16+rem;
    font-size: 1em;
    letter-spacing: 0.1/16+em;
    color: $gray;
  }

  &__header {
    padding: 40/16+em;
    display: flex;
    align-items: center;
    min-height: 189/16+em;

    @media screen and (max-width: $tablet) {
      padding: 1.25em;
      flex-direction: column;
    }

    &:not(:last-child) {
      border-bottom: 1px solid $light-gray;
    }

    img {
      width: 80/16+em;
      flex-shrink: 0;

      @media screen and (min-width: $tablet + 1) {
        margin-right: 27/16+em;
      }

      @media screen and (max-width: $tablet) {
        margin-bottom: 1rem;
      }
    }

    div {
      color: $color-primary;
      font-weight: 500;
      font-size: 20/16+em;
      letter-spacing: -0.1/20+em;

      @media screen and (min-width: $tablet + 1) {
        line-height: 29/20+em;
      }
    }
  }

  &__section {
    padding: 40/16+em;

    @media screen and (max-width: $tablet) {
      padding: 1.25em;
    }
  }

  &__btn {
    background: $color-primary;
    color: #fff;
    font-weight: 600;
    font-size: 12/16+em;
    line-height: 17/12+em;
    text-align: center;
    letter-spacing: 0.35/12+em;
    text-transform: uppercase;
    transition: .3s;
    padding: 31.5/12+em 1.25em;
    margin-top: auto;

    &:hover {
      background: $color-primary-hover;
    }
  }

  &__footer {
    padding: 40/16+em;
    background: #26629F;
    color: #fff;
    margin-top: auto;

    &+.business-card__btn {
      margin-top: 0;
    }

    .title {
      font-weight: 600;
      font-size: 18/16+em;
      line-height: 26/18+em;
      letter-spacing: -0.1/18+em;

      &:not(:last-child) {
        margin-bottom: 12/16+em;
      }
    }

    .value {
      font-weight: 600;
      font-size: 45/16+em;
      line-height: 110%;
      letter-spacing: -0.1/45+em;

      &:not(:last-child) {
        margin-bottom: 9/45+em;
      }
    }

    .value-unit {
      font-size: 14/16+em;
      line-height: 20/14+em;
      letter-spacing: 0.1/14+em;
      margin-bottom: 3/14+em;
    }
  }

  &--small {
    .business-card__header, .business-card__section, .business-card__footer {
      padding: 20/16+em;
    }
  }

  .plain-text {
    color: inherit;

    .orange-link {
      margin-bottom: 0 !important;
    }
  }

  .orange-link {
    &:not(:last-child) {
      margin-bottom: 10/16+rem;
    }
  }
}