.simplebar {

  .simplebar-scrollbar {
    //width: 4px;

    &:before {
      background: $gray;
      opacity: .5;
      left: 8px;
      right: 8px;
    }
  }

  .simplebar-scrollbar.simplebar-visible:before {
    opacity: 1;
  }

  .simplebar-track.simplebar-vertical {
    width: 22px;
  }

  .simplebar-track.simplebar-vertical .simplebar-scrollbar:before {
    top: 8px;
    bottom: 8px;
  }
}