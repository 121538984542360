.form {
  &__title {
    margin-top: 0;
    font-weight: 500;
    font-size: 20/16+em;
    margin-bottom: 1em;
  }
}

.input-group {
  &:not(:last-child) {
    margin-bottom: 20/16+em;
  }

  &__title {
    font-size: 14/16+em;
    display: block;
    margin-bottom: 7/14+em;
    color: $color-primary;
  }

  .alert {
    display: none;
  }

  &.error {
    .alert {
      display: block;
    }
  }

  &__row {
    @media screen and (min-width: $laptop-s + 1) {
      display: flex;
      align-items: center;
      margin-bottom: 5/16+rem;

      .input-group__title {
        width: 257/16+em;
        font-weight: 500;
        font-size: 18/16+em;
        margin-bottom: 0;
        color: $color-primary;
        margin-right: 15/16+rem;
        flex-shrink: 0;

        &+* {
          flex-grow: 1;
        }
      }
    }
  }
}

.input-regular {
  background: #FFFFFF;
  border: 1px solid $border-color;
  box-shadow: inset 0 4px 4px rgba(0, 0, 0, 0.05);
  border-radius: 5px;
  font-size: 18/16+em;
  padding-left: 12/18+em;
  padding-right: 12/18+em;
  height: 50/18+em;
  outline: none !important;
  width: 100%;
  transition: .3s;
  font-family: inherit;
  -webkit-appearance: none;

  @media screen and (max-width: $tablet) {
    font-size: 1em;
  }

  &:focus {
    border-color: $color-primary;
  }
}

textarea.input-regular {
  width: 100% !important;
  padding-top: 0.6em;
  padding-bottom: 0.6em;
  height: 6em;
}

select.input-regular {
  -webkit-appearance: unset;
}

.input-icon {
  position: relative;

  .input-regular {
    padding-right: 50/18+em;
  }

  i {
    position: absolute;
    font-size: 20/16+em;
    color: $color-primary;
    right: 15/20+em;
    top: 50%;
    transform: translateY(-50%);
  }
}

.radio {
  display: inline-block;
  cursor: pointer;

  &:not(:last-child) {
    margin-right: 39/16+em;
  }
  
  input {
    display: none;

    &:checked+span:after {
      opacity: 1;
    }
  }

  span {
    font-weight: bold;
    font-size: 18/16+em;
    color: $color-secondary;
    padding-left: 43px;
    position: relative;
    min-height: 32px;
    display: inline-flex;
    align-items: center;

    &:before {
      content: '';
      width: 32px;
      height: 32px;
      border-radius: 50%;
      background: #EBEBEB;
      position: absolute;
      top: 0;
      left: 0;
    }

    &:after {
      content: '';
      width: 18px;
      height: 18px;
      border-radius: 50%;
      background: #40E366;
      position: absolute;
      top: 7px;
      left: 7px;
      transition: .3s;
      opacity: 0;
    }
  }
}

.radio-switch {
  background: #EBEBEB;
  border-radius: 30px;
  overflow: hidden;
  cursor: pointer;

  input {
    display: none;

    &:checked+div {
      &:before {
        left: calc(50% - 13rem/16);
        background: $green;

        @media screen and (max-width: $laptop-s) {
          left: calc(50% - 8em/16);
        }
      }

      span {
        &:first-child {
          color: #B4B4B5;
        }

        &:last-child {
          color: #fff;
        }
      }
    }
  }

  div {
    display: flex;
    position: relative;

    &:before {
      content: '';
      position: absolute;
      border-radius: 30px;
      left: 0;
      top: 0;
      height: 100%;
      width: calc(50% + 13em/16);
      background: $color-primary;
      transition: .3s;

      @media screen and (max-width: $laptop-s) {
        width: calc(50% + 8em/16);
      }
    }

    span {
      width: 50%;
      font-weight: bold;
      font-size: 18/16+em;
      color: #B4B4B5;
      padding: 21.5/18+em 46/18+em;
      transition: .3s;
      position: relative;
      z-index: 1;
      pointer-events: none;

      &:first-child {
        color: #fff;
        padding-right: 33/18+em;
      }

      &:last-child {
        padding-left: 33/18+em;
      }

      @media screen and (max-width: $laptop-s) {
        padding: 14/18+em 30/18+em;

        &:first-child {
          color: #fff;
          padding-right: 22/18+em;
        }

        &:last-child {
          padding-left: 22/18+em;
        }
      }
    }
  }
}

.file-input {
  display: flex;
  align-items: center;

  input {
    display: none;
  }
}