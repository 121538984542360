.categories {
  display: flex;

  &:not(:last-child) {
    margin-bottom: 30/16+rem;
  }
  
  ul {
    display: flex;
    flex-wrap: wrap;
    list-style: none;
    padding-left: 0;
    margin: -5/16+em;

    @media screen and (max-width: $tablet) {
      justify-content: center;
    }

    li {
      padding: 5/16+em;

      a {
        font-weight: 600;
        font-size: 12/16+em;
        text-align: center;
        letter-spacing: 0.35/12+em;
        text-transform: uppercase;
        display: block;
        color: $color-primary;
        padding: 15px 30px;
        border: 1px solid $light-gray;
        border-radius: 30px;
        transition: .3s;

        &:hover {
          background: $extra-light-gray;
        }
      }

      &.active {
        a {
          border-color: $color-primary;
        }
      }
    }
  }
}