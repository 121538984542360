.stat-card {
  box-shadow: $box-shadow;
  border: 1px solid $light-gray;
  border-radius: 20px;
  background: #fff;
  padding: 30/16+em;
  height: 100%;
  position: relative;

  @include radial-graph(300, 30, 70);

  &__number {
    font-weight: 500;
    font-size: 18/16+em;
    line-height: 156%;
    letter-spacing: 0.5/18+em;
    color: $gray;
    position: absolute;
  }

  .radial-graph {
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 30/16+em;

    @media screen and (max-width: $laptop-m) {
      font-size: 0.8em;
    }

    @media screen and (max-width: $mobile-m) {
      font-size: .65em;
    }
  }

  &__text {
    font-size: 18/16+em;
    line-height: 26/18+em;
    letter-spacing: 0.02em;
    color: #000000;
  }
}