.member {
  &__image {
    position: relative;
    padding-bottom: 100%;
    border-radius: 12px;
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.05);
    margin-bottom: 10/16+em;
    overflow: hidden;

    img {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      width: 100%;
      height: 100%;
      @include object-fit(cover);
    }
  }

  &__title {
    color: #000;
    font-weight: 600;
    font-size: 20/16+em;
    line-height: 120%;
    letter-spacing: -0.1/20+em;
    margin-top: 0;

    &:not(:last-child) {
      margin-bottom: 9/20+em;
    }
  }

  &__position {
    font-size: 12/16+em;
    line-height: 120%;
    letter-spacing: 0.1/12+em;
    color: #828282;
  }
}

.members {
  display: flex;
  flex-wrap: wrap;
  margin: (-30/16+em) (-11.5/16+em);

  &>div {
    width: 20%;
    padding: 30/16+em 11.5/16+em;

    @media screen and (max-width: $laptop-m) {
      width: 25%;
    }

    @media screen and (max-width: $laptop-s) {
      width: calc(100%/3);
    }

    @media screen and (max-width: $tablet) {
      width: 50%;
    }

    @media screen and (max-width: 600px) {
      width: 100%;
    }
  }
}

.member-modal {
  width: 690/16+em;
  max-width: 100%;
  padding: 0;
  border-radius: 20px;
  background: none;

  &__inner {
    display: flex;
    overflow: hidden;

    @media screen and (max-width: $tablet) {
      flex-direction: column;
    }
  }

  &__img {
    width: 220/16+em;
    height: 400/16+em;
    flex-shrink: 0;
    @include object-fit(cover);

    @media screen and (max-width: $tablet) {
      width: 100%;
    }
  }

  &__right {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    padding: 30/16+em 30/16+em 0;
    background: #fff;

    @media screen and (max-width: $tablet) {
      padding: 20/16+em;
    }
  }

  &__title {
    flex-shrink: 0;
    color: #000;
    font-weight: 600;
    font-size: 20/16+em;
    line-height: 120%;
    letter-spacing: -0.1/20+em;
    margin-top: 0;
    margin-bottom: 0;
  }

  &__position {
    flex-shrink: 0;
    font-size: 14/16+em;
    line-height: 120%;
    letter-spacing: 0.1/12+em;
    color: #828282;

    &:not(:last-child) {
      margin-bottom: 18/16+rem;
    }
  }

  &__content {
    flex-grow: 1;

    @media screen and (min-width: $tablet + 1) {
      position: relative;
      margin-right: -30/16+rem;
      overflow: hidden;

      &>div {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        padding-right: 30/16+rem;
      }
    }

    .plain-text {
      font-size: 1em;
      color: #000;
    }
  }
  
  .fancybox-button svg {
    @media screen and (max-width: $tablet) {
      background: #fff;
      border-radius: 50%;
    }
  }
}