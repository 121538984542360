.regulations {
  background: #fff;
  box-shadow: $hard-shadow;
  border-radius: 20px;
  overflow: hidden;

  .row {
    margin: 0 !important;

    &>[class^="col-"] {
      padding: 0 !important;

      &:not(:last-child) {
        @media screen and (min-width: $laptop-s + 1) {
          border-right: 1px solid $light-gray;
        }
      }

      @media screen and (max-width: $laptop-s) {
        border-right: none;
      }
    }
  }
}

.regulation-card {
  padding: 40/16+em 30/16+em;
  display: flex;
  flex-direction: column;
  height: 100%;

  @media screen and (max-width: $tablet) {
    padding: 1.25em;
  }

  &__title {
    font-weight: 600;
    font-size: 20/16+em;
    letter-spacing: -0.1/20+em;
    margin-top: 0;
    margin-bottom: 20/20+em;
  }

  .plain-text {
    color: inherit;

    &:not(:last-child) {
      margin-bottom: 20/16+rem;
    }
  }

  .orange-link {
    margin-top: auto;
    align-self: flex-start;
  }
}