.service-card {
  padding: 20/16+em;
  border: 1px solid #EEEEEE;
  border-radius: 20px;
  background: #FFFFFF;
  transition: .3s;
  height: 100%;
  display: flex !important;
  flex-direction: column;

  &:hover {
    box-shadow: 0 0 30px rgba(0,0,0,.02);
  }

  &__count {
    font-size: 18/16+em;
    line-height: 26/18+em;
    letter-spacing: 0.02em;
    color: $gray;
    margin-bottom: 14/18+em;
  }

  &__img {
    width: 80/16+em;
    height: 80/16+em;
    @include object-fit(contain);
    display: block;
    margin: 0 auto 30/16+em;
  }

  &__title {
    margin-top: 0;
    font-weight: 600;
    font-size: 14/16+em;
    line-height: 20/14+em;
    color: #000;
    margin-bottom: 25/14+rem;

    @media screen and (max-width: $tablet) {
      font-size: 1.25em;
    }
  }

  .plain-text {
    font-size: 14/16+em;

    @media screen and (max-width: $tablet) {
      font-size: 1em;
    }

    &:not(:last-child) {
      margin-bottom: 32/16+em;
    }

    a:not(:hover) {
      color: #454545;
    }
  }

  .ghost-btn {
    display: table;
    margin: auto auto 0;
    min-width: 200/16+rem;
  }
}