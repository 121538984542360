.catalog {
  display: flex;

  &__left {
    width: 470/16+em;
    flex-shrink: 0;
    border-right: 1px solid $light-gray;
  }

  &__right {
    flex-grow: 1;
    position: relative;
  }
}

.catalog-link {
  line-height: 24/16+em;
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: #000000;
  opacity: 0.7;
  padding: 14.5/16+em 10/16+em 14.5/16+em 40/16+em;
  transition: .3s;
  cursor: pointer;

  &:not(:last-child) {
    border-bottom: 1px solid $light-gray;
  }

  &:after {
    font-family: icomoon;
    content: "\e90f";
    font-size: 1.5em;
    margin-left: 26/16+em;
  }

  &:hover {
    background-color: #F4F7FD;
  }

  &.active {
    background-color: #F4F7FD;
    opacity: 1;
    //font-weight: bold;

    &:after {
      font-weight: bold;
    }
  }
}

.catalog-item {
  border: 1px solid $light-gray;
  border-radius: 12px;
  display: flex;

  &:not(:last-child) {
    margin-bottom: 20/16+em;
  }

  &__body {
    width: 484%/720*100;
    flex-shrink: 0;
    padding: 20/16+em;
  }

  &__title {
    margin-top: 0;
    font-weight: 500;
    font-size: 20/16+em;
    color: #000000;
    margin-bottom: 0;
  }

  &__text {
    font-weight: 500;
    font-size: 14/16+em;
    color: $gray;
  }

  &__attribute {
    flex-grow: 1;
    padding: 20/16+em;
    border-left: 1px solid $light-gray;
  }

  &__phone {
    font-size: 18/16+em;
    line-height: 140%;
    color: $gray;
  }
}

.catalog-tab {
  padding: 20/16+em 50/16+em;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: none;
  //overflow-x: hidden;

  &.active {
    display: block;
  }
}