.menu-btn {
  position: relative;
  width: 18px;
  height: 14px;
  transition: .5s ease-in-out;
  cursor: pointer;

  span {
    display: block;
    position: absolute;
    height: 2px;
    width: 100%;
    background: $color-primary;
    opacity: 1;
    left: 0;
    transform: rotate(0deg);
    transition: .25s ease-in-out;

    &:nth-child(1) {
      top: 0;
    }

    &:nth-child(2),
    &:nth-child(3) {
      top: 6px;
    }

    &:nth-child(4) {
      top: 12px;
    }
  }

  @media screen and (min-width: $laptop-s + 1) {
    display: none;
  }

  &.opened  span {
    &:nth-child(1) {
      top: 6px;
      width: 0;
      left: 50%;
    }

    &:nth-child(2) {
      -webkit-transform: rotate(45deg);
      -moz-transform: rotate(45deg);
      -o-transform: rotate(45deg);
      transform: rotate(45deg);
    }

    &:nth-child(3) {
      -webkit-transform: rotate(-45deg);
      -moz-transform: rotate(-45deg);
      -o-transform: rotate(-45deg);
      transform: rotate(-45deg);
    }

    &:nth-child(4) {
      top: 6px;
      width: 0;
      left: 50%;
    }
  }
}