.spoilers {
  .spoiler {
    border-radius: 20px;
    border: 1px solid $light-gray;
    overflow: hidden;
    box-shadow: $box-shadow;
    position: relative;

    &:not(:last-child) {
      margin-bottom: 20/16+em;
    }

    &__title {
      display: flex;
      flex-direction: column;
      justify-content: center;
      cursor: pointer;
      transition: .3s;
      color: #494949;
      background: #fff;
      border-radius: 5px;
      min-height: 100/16+em;

      @media screen and (min-width: $tablet + 1) {
        padding-right: 105/16+em;
      }

      @media screen and (max-width: $tablet) {
        flex-direction: row;
        align-items: center;
      }

      .news-title {
        &:last-child {
          margin-bottom: 0;
        }
      }

      & > div {
        display: flex;
        //flex-grow: 1;

        i {
          color: $color-primary;
          margin-right: 25/16+rem;

          @media screen and (min-width: $laptop-s + 1) {
            transform: scale(1.25);
          }

          @media screen and (max-width: $laptop-s) {
            margin-right: 1rem;
          }
        }
      }

      &:after {
        content: "\e908";
        font-family: 'icomoon';
        display: flex;
        align-items: center;
        justify-content: center;
        border: 1px solid $light-gray;
        transition: .3s;
        flex-shrink: 0;
        font-size: 1em;
        color: #93969D;
        width: 54/16+em;
        height: 54/16+em;
        border-radius: 50%;

        @media screen and (min-width: $tablet + 1) {
          position: absolute;
          z-index: 2;
          bottom: 23/16+rem;
          right: 20/16+rem;
          padding-top: 2/16+em;
        }

        @media screen and (max-width: $tablet) {
          margin: 1rem;
          font-size: .8em;
        }
      }

      &:hover {
        &:after {
          background: $extra-light-gray;
        }
      }
    }

    &__desc {
      display: none;
      background: #fff;
      animation: fade .8s forwards;
      opacity: 0;
      border-radius: 0 0 5px 5px;

      @media screen and (min-width: $tablet + 1) {
        padding-right: 105/16+em;
      }

      & > div {
        display: flex;
      }

      & > *:last-child {
        margin-bottom: 0;
      }

      @keyframes fade {
        0% {
          opacity: 0;
        }
        100% {
          opacity: 1;
        }
      }
    }

    &.active {
      .spoiler__title {
        &:after {
          transform: rotate(180deg);
        }
      }

      .spoiler__desc {
        display: block;
      }
    }

    &__date {
      width: 116/16+em;
      padding: 20/16+em;
      display: flex;
      align-items: center;
      border-right: 1px solid $light-gray;
      flex-shrink: 0;
    }

    &__preview {
      padding: 20/16+em;

      .paragraph {
        margin-bottom: 7/16+rem;
      }
    }

    &__text {
      padding: 20/16+em;
      padding-top: 0;

      h4 {
        margin-bottom: 0;
      }
    }

    .plain-text {
      font-size: 1em;
      color: #000;
    }
  }
}
