.about-header {
  display: flex;
  border-radius: 20px;
  box-shadow: $hard-shadow;
  position: relative;

  @media screen and (max-width: $laptop-s) {
    flex-direction: column;
  }

  .blue-block {
    border-radius: 20px 0 0 20px;

    @media screen and (max-width: $laptop-s) {
      border-radius: 20px 20px 0 0;
    }
  }

  &__img {
    border-radius: 0 20px 20px 0;
    width: 500/16+em;
    flex-shrink: 0;
    background: #67ABCB;
    display: flex;
    align-items: flex-end;

    @media screen and (max-width: $laptop-s) {
      width: 100%;
      border-radius: 0 0 20px 20px;
    }

    img {
      width: 100%;
      margin-bottom: -30/16+em;

      @media screen and (max-width: $laptop-s) {
        margin-bottom: -2.575em;
      }

      @media screen and (max-width: $tablet) {
        margin-bottom: -1.775em;
      }

      @media screen and (max-width: 500px) {
        margin-bottom: -5.675vw;
      }
    }
  }
}