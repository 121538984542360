.team {
  box-shadow: 10px 16px 60px rgba(27, 65, 103, 0.06);
  background: #fff;
  border-radius: 20px;

  &:not(:last-child) {
    margin-bottom: 80/16+rem;
  }

  &__preview {
    background: $color-primary;
    padding: 50/16+em;
    color: #fff;
    border-radius: 20px 20px 0 0;
    transition: .3s;
    position: relative;

    @media screen and (max-width: $tablet) {
      padding: $padding-inner-mobile;
      padding-bottom: 50/16+rem;
    }
  }

  &__title {
    font-weight: 600;
    font-size: 24/16+em;
    margin-bottom: 20/16+rem;
    margin-top: 0;
  }

  .plain-text {
    color: #fff;
  }

  &__btn {
    display: flex;
    font-weight: 600;
    font-size: 18/16+em;
    line-height: 26/18+em;
    min-width: 187/18+em;
    align-items: center;
    justify-content: center;
    letter-spacing: -0.1/18+em;
    background: #FFFFFF;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    border-radius: 60px;
    padding: 15.5/18+em 25/18+em;
    transition: .3s;
    position: absolute;
    z-index: 1;
    color: #000;
    bottom: 0;
    left: 50/16+rem;
    transform: translateY(50%);

    @media screen and (max-width: $tablet) {
      left: 20/16+rem;
    }
    
    span {
      flex-grow: 1;
      text-align: center;

      &:nth-of-type(2) {
        display: none;
      }
    }

    i {
      //font-size: 24/18+em;
      margin-left: 12/18+em;
      transition: .3s;
      display: block;
    }

    &:hover {
      background: $extra-light-gray;
    }
  }

  &__desc {
    padding: 50/16+em;
    border-radius: 0 0 20px 20px;
    transition: opacity .6s .2s;

    @media screen and (max-width: $tablet) {
      padding: $padding-inner-mobile;
      padding-top: 50/16+rem;
    }
  }

  &.minimized {
    .team__preview {
      border-radius: 20px;
    }

    .team__btn {
      span {
        &:first-child {
          display: none;
        }

        &:nth-of-type(2) {
          display: block;
        }
      }

      i {
        transform: rotate(180deg);
      }
    }

    .team__desc {
      opacity: 0;
    }
  }
}