.partners {
  padding: 1.5em 0 3em;

  a {
    display: table !important;
    width: auto !important;
    transition: .3s;

    @media screen and (max-width: $laptop-s) {
      margin: 0 auto;
    }

    img {
      width: 120/16+em;
      height: 32/16+em;
      @include object-fit(contain);
    }

    &:hover {
      opacity: .75;
    }
  }
}