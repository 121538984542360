.article {
  .title-primary {
    @media screen and (max-width: $laptop-s) {
      margin-bottom: 1.5rem;
    }
  }

  &__image {
    position: relative;
    padding-bottom: 478%/968*100;
    display: block;
    margin-bottom: 20/16+rem;
    border-radius: 5px;
    overflow: hidden;

    img {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      @include object-fit(cover);
    }
  }

  &__meta {
    font-size: 18/16+em;
    color: $gray-text;
    margin-bottom: 1rem;
  }
}