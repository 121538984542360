.pagination {
  margin-top: 73/16+em;
  flex-wrap: wrap;
  display: flex;
  list-style: none;
  padding-left: 0;
  margin-bottom: 0;
  justify-content: center;

  @media screen and (max-width: $tablet) {
    margin-top: 1em;
  }

  i {
    display: inline-block;
    font-weight: normal;
    vertical-align: middle;
    font-size: .8em;
  }

  &>li {
    &:not(:last-child) {
      margin-right: 10/16+rem;
    }

    &>a, &>span {
      font-size: 14/16+em;
      font-weight: 600;
      transition: .3s;
      padding: 0 0.5em;
      text-align: center;
      min-width: 30/14+em;
      display: flex;
      align-items: center;
      justify-content: center;
      height: 30/14+em;
      color: #ADAFB6;
      background: #fff;
      border: 1px solid #EDEDED;
      border-radius: 2px;

      @media screen and (max-width: $tablet) {
        margin-right: .5em;
        margin-bottom: .5em;
        min-width: 2.5em;
        height: 2.5em;
        border-width: 2px;
      }

      &:hover,
      &:focus {
        color: $color-primary-hover;
        border-color: $color-primary-hover;
      }
    }

    &.active {
      &>a, &>span{
        background: $color-primary;
        border-color: $color-primary;
        color: #fff;
        pointer-events: none;
      }
    }

    &.disabled  {
      a, span {
        pointer-events: none;
        color: $gray-text !important;
      }
    }
  }
}