.btn {
  font-weight: 600;
  font-size: 12/16+em;
  text-align: center;
  -webkit-appearance: none;
  background: $color-primary;
  color: #fff;
  letter-spacing: 0.35/12+em;
  padding: 15.5/12+em 25/12+em;
  border-radius: 50px;
  transition: .3s;
  position: relative;
  min-width: 191/12+em;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  text-transform: uppercase;
  border: none;
  cursor: pointer;
  font-family: inherit;
  line-height: unset;
  height: unset;
  width: unset;
  box-shadow: unset;
  margin: unset;
  outline: none !important;
  -webkit-appearance: none;

  &:hover {
    background: $color-primary-hover;
    color: #fff;
  }

  span {
    padding-right: 13/16+em;
  }

  i {
    font-size: 16/12+em;

    &+span {
      padding-left: 13/16+em;
      padding-right: 0;
    }
  }

  &--white {
    background: #fff;
    color: #000 !important;

    &:hover {
      background: rgba(255,255,255,.8);
    }
  }

  &--dark {
    background: $color-secondary;

    &:hover {
      background: $color-secondary-hover;
    }
  }

  &--gray {
    background: $color-tertiary;

    &:hover {
      background: $color-tertiary-hover;
    }
  }

  &--small {
    font-size: 10/16+em;
    padding: 11.5/10+em 26.5/10+em;
    min-width: unset;

    i {
      font-size: 14/10+em;
    }
  }

  &--high {
    @media screen and (min-width: $tablet + 1) {
      padding: 25/12+em 35/12+em;
    }
  }

  &--big {
    @media screen and (min-width: $tablet + 1) {
      font-size: 18/16+em;
      font-weight: 600;
      padding: 18.5/18+em 32/18+em;
      min-width: 220/18+em;
      text-transform: unset;
    }
  }
}

.ghost-btn {
  font-weight: 600;
  font-size: 12/16+em;
  text-align: center;
  -webkit-appearance: none;
  border: 1px solid;
  color: $color-primary;
  text-transform: uppercase;
  padding: 14.5/12+em 24/12+em;
  letter-spacing: 0.35/12+em;
  border-radius: 50px;
  transition: .3s;
  position: relative;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  font-family: inherit;
  line-height: unset;
  height: unset;
  width: unset;
  box-shadow: unset;
  cursor: pointer;
  background: none;
  outline: none !important;
  -webkit-appearance: none;

  &:hover {
    background: $color-primary-hover;
    border-color: $color-primary-hover;
    color: #fff;
  }

  span {
    padding-right: 13/16+em;
  }

  i {
    font-size: 16/12+em;

    &+span {
      padding-left: 13/16+em;
      padding-right: 0;
    }
  }

  &--small {
    font-size: 10/16+em;
    padding: 10.5/10+em 25.5/10+em;
    min-width: unset;

    i {
      font-size: 14/10+em;
    }
  }

  &--square {
    min-width: 170/16+rem;
    border-radius: 5px;
  }
}

.btn-transparent {
  font-weight: 500;
  font-size: 18/16+em;
  display: flex;
  align-items: center;
  -webkit-appearance: none;
  padding: 6.5/18+em 14/18+em;
  color: $color-secondary;
  border-radius: 50px;
  transition: .3s;
  background: none;

  @media screen and (max-width: $laptop-s) {
    background: #dfe3e8;
  }

  &:hover {
    background: #DFE3E8;
  }

  i {
    font-size: 10/18+em;
    transform: translateY(1/10+em);

    &:last-child {
      margin-left: 1em;
    }
  }
}

.link {
  font-weight: 600;
  font-size: 18/16+em;
  color: $gray-text;
  transition: .3s;

  &:hover {
    color: $gray-text-hover;
  }

  &--orange {
    color: $color-secondary;
    transition: .3s;

    &:hover {
      color: $color-secondary-hover;
    }
  }
}

.orange-link {
  font-weight: 600;
  font-size: 12/16+rem;
  text-align: center;
  letter-spacing: 0.35/12+em;
  text-transform: uppercase;
  color: #FFFFFF;
  background: $color-secondary;
  transition: .3s;
  border-radius: 5px;
  padding: 3/12+em 8/12+em;
  display: inline-block;
  line-height: normal;
  vertical-align: middle;

  &[href]:hover {
    background: $color-secondary-hover;
  }
}

.title-primary {
  font-weight: 600;
  font-size: 45/16+em;
  line-height: 112%;
  letter-spacing: -1/45+em;
  color: #222222;
  margin-top: 0;
  margin-bottom: 0;

  @media screen and (max-width: $laptop-m) {
    font-size: 2.5em;
  }

  @media screen and (max-width: $tablet) {
    font-size: 2em;
  }

  &:not(:last-child) {
    margin-bottom: 60/16+rem;

    @media screen and (max-width: $tablet) {
      margin-bottom: 20/16+rem;
    }
  }

  &--big {
    font-size: 64/16+em;
  }

  &+.subtitle {
    margin-top: -3rem;

    @media screen and (max-width: $tablet) {
      margin-top: -0.5rem;
    }
  }
}

.subtitle {
  color: $gray;
  font-size: 18/16+em;
  margin-bottom: 40/16+em;

  @media screen and (max-width: $tablet) {
    margin-bottom: 20/16+rem;
  }
}

.title-secondary {
  font-weight: 600;
  font-size: 35/16+em;
  line-height: 110%;
  margin-top: 0;
  margin-bottom: 0;
  letter-spacing: -0.1/35+em;

  &:not(:last-child) {
    margin-bottom: 35/35+em;
  }

  @media screen and (max-width: $laptop-m) {
    font-size: 2em;
  }

  @media screen and (max-width: $tablet) {
    font-size: 1.5em;
  }
}

.title-block {
  display: flex;
  align-items: center;
  margin-bottom: 60/16+em;
  justify-content: space-between;

  .title-primary {
    margin-bottom: 0;
  }

  @media screen and (max-width: $tablet) {
    flex-direction: column;
    align-items: flex-start;
    margin-bottom: 20/16+rem;

    .title-primary {
      margin-bottom: 0.5rem;
    }
  }

  .link {
    white-space: nowrap;

    @media screen and (min-width: $tablet + 1) {
      margin-top: 0.7em;
    }
  }
}

.news-title {
  font-weight: 600;
  font-size: 20/16+em;
  letter-spacing: -0.1/20+em;
  color: #000000;
  margin-top: 0;

  &:not(:last-child) {
    margin-bottom: 5/20+em;
  }
}

.semibold-18 {
  font-weight: 600;
  font-size: 18/16+em;
  line-height: 26/18+em;
  letter-spacing: -0.1/18+em;
  color: #000000;
  margin-top: 0;

  &:not(:last-child) {
    margin-bottom: 13/18+em;
  }
}

.paragraph {
  color: $gray;
  font-size: 14/16+em;
  line-height: 20/14+em;
  letter-spacing: 0.1/14+em;

  &>*:last-child {
    margin-bottom: 0;
  }
}

.w-100 {
  width: 100%;
}