.mission-card {
  border-radius: 12px;
  height: 100%;
  background: $color-primary;
  display: flex;
  overflow: hidden;

  &__desc {
    padding: 40/16+em;
  }

  &__number {
    font-size: 18/16+em;
    letter-spacing: 0.02em;
    color: #FFFFFF;
    opacity: 0.5;
    margin-bottom: 20/18+em;
  }

  &__text {
    font-size: 18/16+em;
    line-height: 26/18+em;
    letter-spacing: 0.02em;
    color: #FFFFFF;
  }

  &__img {
    width: 200/16+em;
    @include object-fit(cover);
    mix-blend-mode: luminosity;
    flex-shrink: 0;
  }
}