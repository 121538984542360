.white-block {
  background: #FFFFFF;
  box-shadow: 10px 16px 60px rgba(27, 65, 103, 0.06);
  border-radius: 20px;
  padding: 50/16+em;

  @media screen and (max-width: $tablet) {
    padding: $padding-inner-mobile;
  }

  &:not(:last-child) {
    margin-bottom: 50/16+em;
  }

  .title-primary {
    margin-bottom: 30/16+rem;
  }
}