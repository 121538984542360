.links {
  list-style: none;
  margin-top: 40/16+em;
  padding-left: 0;
  margin-bottom: 0;

  &:not(:last-child) {
    margin-bottom: 40/16+em;
  }

  li {
    &:not(:last-child) {
      margin-bottom: 40/16+em;

      @media screen and (max-width: $laptop-s) {
        margin-bottom: 20/16+em;
      }
    }

    a {
      font-size: 1.125em;
      color: #2F80ED;
      transition: .3s;

      &:hover {
        color: #2a6ccb;
      }
    }
  }
}