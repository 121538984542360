.gallery-item {
  display: block;
  padding-bottom: 56%;
  position: relative;

  img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    @include object-fit(cover);
    display: block;
  }
}