.left-nav {
  background: #F7F8FA;
  border-radius: 5px;

  &__title {
    display: flex;
    justify-content: space-between;
    font-weight: 600;
    font-size: 18/16+em;
    line-height: 26/18+em;
    letter-spacing: -0.1/18+em;
    cursor: pointer;
    padding: 20/16+rem;
    align-items: center;
    transition: .3s;

    &:after {
      font-size: 16/18+em;
      content: "\e908";
      font-family: icomoon;
      color: #9EA0A8;
      margin-left: 10/16+rem;
      transition: .3s;
      transform: rotate(180deg);
    }

    &:hover {
      color: $color-primary;
    }
  }

  &__list {
    list-style: none;
    margin-bottom: 0;
    padding: 0 20/16+rem 20/16+rem;
    margin-top: 0;
    transition: opacity .4s;

    &:not(:last-child) {
      margin-bottom: 1rem;
    }

    li {
      display: flex;

      a {
        transition: .3s;

        &:hover {
          color: $color-primary;
        }
      }

      &:not(:last-child) {
        margin-bottom: 10/16+em;
      }

      &:before {
        content: '';
        width: 4px;
        height: 4px;
        margin-right: 1rem;
        background: #9EA0A8;
        border-radius: 50%;
        flex-shrink: 0;
        margin-top: 0.75rem;
        transition: .3s;
      }

      &.active {
        a {
          font-weight: 500;
          color: $color-primary;
        }

        &:before {
          background: $color-primary;
          border-radius: 0;
          transform: rotate(45deg);
        }
      }
    }
  }

  &.collapsed {
    .left-nav__title:after {
      transform: rotate(0);
    }

    .left-nav__list {
      opacity: 0;
    }
  }
}