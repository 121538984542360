.plain-text {
  font-size: 18/16+em;
  line-height: 26/18+em;
  //color: #454545;

  @media screen and (max-width: $tablet) {
    font-size: 1em;
  }

  ul {
    list-style: none;
    margin-top: 0;
    padding-left: 0;
    margin-bottom: 0;

    &:not(:last-child) {
      margin-bottom: 1rem;
    }

    li {
      display: flex;

      &:not(:last-child) {
        margin-bottom: 10/16+em;
      }

      &:before {
        content: '';
        width: 4px;
        height: 4px;
        margin-right: 1rem;
        margin-left: 2px;
        background: #D9D9D9;
        border-radius: 50%;
        flex-shrink: 0;
        margin-top: 0.75rem;
      }
    }

    &[style="list-style-type: circle;"] {
      li {
        &:not(:last-child) {
          margin-bottom: 30/16+em;
        }

        &:before {
          margin-top: 0.6rem;
          width: 8px;
          height: 8px;
          margin-right: calc(1rem - 2px);
          background: $color-secondary;
        }
      }
    }
  }

  ol {
    list-style: none;
    margin-top: 0;
    padding-left: 0;
    margin-bottom: 0;

    &:not(:last-child) {
      margin-bottom: 1rem;
    }

    li {
      display: flex;
      counter-increment: number;

      &:not(:last-child) {
        margin-bottom: 10/16+em;
      }

      &:before {
        content: counter(number) '. ';
        margin-right: 1rem;
        color: #D9D9D9;
        flex-shrink: 0;
      }
    }
  }

  a {
    color: $color-primary;
    transition: .3s;

    &:hover {
      color: $color-primary-hover;
    }
  }

  &>*:last-child {
    margin-bottom: 0;
  }

  iframe {
    width: 100%;
  }

  img {
    height: auto !important;
    display: block;
    margin: 0 auto 1rem;

    @media screen and (max-width: $tablet) {
      width: 100% !important;
    }
  }

  blockquote {
    margin: 0 0 1rem;
    padding: 2em;
    background: rgba(0,0,0,.05);

    &>*:last-child {
      margin-bottom: 0;
    }
  }

  .videoWrapper {
    position: relative;
    padding-bottom: 56.25%; /* 16:9 */
    height: 0;
    margin-bottom: 1rem;
  }

  .videoWrapper object,
  .videoWrapper embed,
  .videoWrapper iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }

  h4 {
    font-weight: 600;
    font-size: 20/18+em;
    letter-spacing: -0.1/20+em;
    margin-top: 0;
    margin-bottom: 0;

    &:not(:last-child) {
      margin-bottom: 10/20+em;
    }
  }

  hr {
    border: none;
    border-bottom: 1px solid $light-gray;
    width: 280/16+em;
    max-width: 100%;
    margin: 30/16+rem 0;
  }
}