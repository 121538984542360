.info-banner {
  display: flex;
  background: linear-gradient(275.36deg, #67ABCB 38.07%, #4A96BA 85.66%);
  box-shadow: $box-shadow;
  border-radius: 20px;
  overflow: hidden;

  &__desc {
    flex-grow: 1;
    padding: 50/16+em 0 50/16+em 50/16+em;

    @media screen and (max-width: $laptop-m) {
      padding: 50/16+em;
    }

    @media screen and (max-width: $tablet) {
      padding: $padding-inner-mobile;
    }

    .title-primary {
      color: #fff;
    }

    .plain-text {
      color: #fff;
    }
  }

  &__img {
    margin-left: 30/16+em;
    flex-shrink: 0;
    width: 447/16+em;
    @include object-fit(contain, bottom center);

    @media screen and (max-width: $laptop-m) {
      display: none;
    }
  }
}